@import 'variables';

/*Layout*/
// Responsive at different break points
@media (min-width: 50px) and (max-width: 767px) {
	.ui-menubar, #app-branding #app-login {
		display: none;
	}
	.ui-sidebar .ui-menubar, .sidebar-app-login, .btn-sidebar {
		display: block;
	}
	.quicklinks-list li a {
		padding: .15em;
	}
	#app-footer * {
		text-align: center;
	}
	#app-branding .logo {
		max-width: 90px;
		transition: max-width .5s ease-in;
	}
}

@media (min-width: 50px) and (max-width: 479px) {
	#app-header #app-branding #app-name {
		text-align: center;
	}
	#app-header #app-branding .logo {
		max-width: 95px;
		transition: max-width .5s ease-in;
	}
	#app-header #app-branding #app-icon {
		align-self: flex-start;
		padding-top: .30em;
	}
}
// Below media queries belongs to ford logo in header at different break points
@media (min-width: 480px) {
	#app-header #app-branding .logo {
		max-width: 100px;
		transition: max-width .5s ease-in;
	}
}

@media (min-width: 768px) {
	#app-header #app-branding .logo {
		max-width: 105px;
		transition: max-width .5s ease-in;
	}
}

@media (min-width: 960px) {
	#app-header #app-branding .logo {
		max-width: 110px;
		transition: max-width .5s ease-in;
	}
}

@media (min-width: 1024px) and (max-width: 1286px) {
	#app-header #app-branding .logo {
		max-width: 115px;
		transition: max-width .5s ease-in;
	}
}

@media (min-width: 1287px) {
	#app-header #app-branding .logo {
		max-width: 120px;
		transition: max-width .5s ease-in;
	}
}












/*Theme*/
@import "variables";

// Below media queries belongs text besides ford logo at different break points
@media (min-width: 50px) and (max-width: 479px) {
	#app-header #app-branding #app-name h1 {
		font-size: 1.25rem; //18px; this is what it used to be before converting to rems
		transition: $app-header-transition;
	}
}

@media (min-width: 480px) {
	#app-header #app-branding #app-name h1 {
		font-size: 1.25rem; /*20px; this is what it used to be before converting to rems*/
		transition: $app-header-transition;
	}
}

@media (min-width: 768px) {
	#app-header #app-branding #app-name h1 {
		font-size: 1.375rem; //22px; this is what it used to be before converting to rems
		transition: $app-header-transition;
	}
}

@media (min-width: 960px) {
	#app-header #app-branding #app-name h1 {
		font-size: 1.5rem; //24px; this is what it used to be before converting to rems
		transition: $app-header-transition;
	}
}

@media (min-width: 1024px) and (max-width: 1286px) {
	#app-header #app-branding #app-name h1 {
		font-size: 1.625rem; //26px; this is what it used to be before converting to rems
		transition: $app-header-transition;
	}
}

@media (min-width: 1287px) {
	#app-header #app-branding #app-name h1 {
		font-size: 1.75rem; //28px; this is what it used to be before converting to rems
		transition: $app-header-transition;
	}
}