/* Prime Designer version 7.0.1 */

@import '../../variables';
// designer overrides

body .ui-dataview .ui-dataview-content{
	padding: 0;
}

// override for border properties in list view
@media screen and (max-width: 40em){
	body .ui-table.ui-table-responsive .ui-table-tbody > tr > td {
		border: $tableBodyCellBorder;
	}
}