@import 'variables';

/*Layout*/
fieldset {
	padding: 0;
	margin: 0;
	border: 0;
}
.form-actions {
	padding: 1em;
	margin: 0 -.3125em .3125em -.3125em;
}
/* primeng doesn't provide much form styling. add back in */
input[type="text"], input[type="number"], textarea, select {
	width: 100%;
}
input[type="text"], input[type="number"] {
	border: 1px solid #a6a6a6;
	border-radius: 3px;
	padding: 5px;
	height: 34px;
}
// auto complete margin
.ui-autocomplete-token{
	margin: .125em;
}
input[type="text"], input[type="number"] {
	height: 34px;
}
form .ui-dropdown{
	width: 100% !important;
	height: 34px;
	padding: .125em;
}

// calender width
.ui-calendar input{
	width: 80%;
}

/* primeng doesn't provide much form styling. add back in */
.ui-autocomplete, .ui-autocomplete .ui-autocomplete-multiple-container.ui-inputtext{
	width: 100%;
}

form{
	border-radius: 5px;
	padding: .5em;
	box-shadow: 0 1px 10px #ccc;
}

// variables.scss file font-size override
body .ui-inputtext{
	font-size: 15px;
}
// primeng override
.ui-autocomplete {
	width: 100% !important;
}
// prime designer override
body .ui-chkbox{
	margin: 4px;
}









/*Theme*/

// border-top for cancel and continue buttons on create booking page
.form-actions {
	border-top: 1px solid #e5e5e5;
}
label, legend {
	font-weight: 700;
	font-size: 1rem;
}
fieldset label {
	font-weight: 400;
}

/* primeng doesn't provide much form styling. add back in */
input[type="text"], input[type="number"], textarea, select {
	padding: .3125em;
	font-size: 1em;
	font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
}
.ui-widget input, .ui-widget select, .ui-widget textarea, .ui-widget button {
	font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
	font-size: 1em;
}