/* Prime Designer version 7.0.1 */

@import '../../variables';

// designer overrides

// using this for side bar
body .ui-sidebar{
	padding: 0;
}

// side bar close icon color
body .ui-sidebar .ui-sidebar-close{
	color: $primaryDarkerColor;
}