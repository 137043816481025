
/*Layout*/
/* primeng doesn't have layout helpers. add them here */
//footer text to be centered here
.text-center {
	text-align: center;
}
.text-left {
	text-align: left;
}
.text-right {
	text-align: right;
}


/*Theme*/
.nowrap{
	white-space: nowrap;
}