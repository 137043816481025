/*start overriding p-menubar style for swt menu*/
@import '~material-design-icons/iconfont/material-icons.css';

mat-icon {
	font-family: 'Material Icons' !important;
}

#car-nav .ui-menubar {
	padding: 0;
	background: linear-gradient(to bottom, #5883A5 0%, #0F4C79 100%);
	-webkit-box-shadow: 0 5px 15px 4px rgb(190, 190, 190);
	-moz-box-shadow: 0 5px 15px 4px rgb(190, 190, 190);
	box-shadow: 0 5px 15px 4px rgb(190, 190, 190);
}

#car-nav .ui-menuitem .ui-menuitem-link {
	color: #fff;
	font-weight: bold;
	padding: 1em;
	border-radius: 0;
	border-right: 1px solid rgb(190, 190, 190);
}

#car-nav .ui-menubar.ui-corner-all {
	border-radius: 5px;
}

#car-nav .ui-state-active {
	background: linear-gradient(to bottom, #596B83 0%, #163353 100%);
}

.ui-menubar .ui-menubar-root-list > li ul {
	background: #3b6d93;
}

.ui-menubar .ui-submenu-list {
	min-width: 20.5em;
}

html, body {
	height: 100%;
}

body {
	margin: 0;
	font-family: Roboto, "Helvetica Neue", sans-serif;
	overflow-x: auto;
}

/*p-dialog .ui-dialog {
	overflow: scroll;
}*/

/*html, body {*/
/*	height: 100%;*/
/*}*/

/*body {*/
/*	margin: 0;*/
/*	font-family: Roboto, "Helvetica Neue", sans-serif;*/
/*}*/

.ui-panel .ui-widget-header, .ui-panel-header {
	background: linear-gradient(to bottom, #296089 0%, #cee5f9 100%) !important;
	border-color: #9fcbf1;
}

#cdk-step-content-0-4 > form > div:nth-child(1) > shared-loading-charts > p-dialog > div > div.ui-dialog-content.ui-widget-content{
	overflow: scroll;
	height: 78vh;
}

#oeeChartDialog > div > div.ui-dialog-content.ui-widget-content {
	overflow: scroll !important;
	height: 70%;
}

.text-break {
	word-break: break-word;
}

.w-20 {
	width: 20%;
}

body .ui-state-disabled, body .ui-widget:disabled {
	opacity: 0.9;
}

button.ui-button.ui-widget:disabled {
	opacity: 0.5;
}

.ui-datepicker-calendar .ui-state-disabled {
	opacity: 0.5;
}

.text-align-center {
	text-align: center;
}

.p05 {
	padding: 0.5em;
}

.mr-0 {
	margin-right: 0;
}

.ml-05 {
	margin-left: 0.5em!important;
}

.display-flex {
	display: flex;
}

.flex-grow-1 {
	flex-grow: 1;
}