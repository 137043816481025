@import 'variables';

/*Layout*/
dl, h5 {
	margin: 0;
	padding: 0;
}
dd {
	margin-left: 0;
	margin-top: .5rem;
	word-wrap: break-word;
}
body .ui-panel .ui-panel-footer{
	background-color: #e3f2ff ;
}












/*Theme*/
dt {
	font-weight: $FordAntennaBold;
}