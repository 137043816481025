@import 'variables';

/*Layout*/

/* primeng tables are fixed cell widths. gross. override back to default */
// data table layout
.ui-datatable table {
	table-layout: auto;
}

// steps numbers properties in create booking page
.ui-steps .ui-steps-item.ui-state-highlight, .ui-steps .ui-steps-item.ui-state-default{
	border: none;
	background: none;
}

// steps numbers properties in create booking page
.ui-steps .ui-steps-item .ui-steps-number {
	display: inline-block;
	width: 36px;
	margin-top: -.875em;
	margin-bottom: .625em;
}

// datatable layout property
.ui-table table {
	table-layout: auto;
}

// search results page actions links padding
.ui-panel-footer button, .ui-panel-footer span {
	padding: .5em;
}

// checkbox alignment
body .ui-table .ui-table-thead > tr > th,
body .ui-table .ui-table-tbody > tr > td  {
	padding: 0.571em 0.350em;
}

/*Theme*/

//steps numbers properties
.ui-steps .ui-steps-item .ui-steps-number {
	background-color: #0081c2;
	color: #FFFFFF;
	border-radius: 50%;
}

.ui-panel .ui-widget-header, .ui-panel-header {
	background: linear-gradient(to bottom, $ui-panel-bg 0%, #cee5f9 100%) !important;
	border-color: $ui-panel-bg;
}
.ui-widget-content {
	border: 1px solid #abd8ec;
}

.ui-panel-footer .ui-button, .ui-panel-footer .ui-button:hover {
	color: #337ab7 !important;
	background: none !important;
	border: 0 !important;
}
// sortable icon color in data table
body .ui-table .ui-sortable-column .ui-sortable-column-icon{
	color: #1b1d1f;
}