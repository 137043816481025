/* Prime Designer version 7.0.1 */
@import '../../variables';
// designer overrides

// Menu related properties
body .ui-menubar {
	background: $primaryDarkerColor;
	.ui-menuitem-link .ui-menuitem-text,
	.ui-menuitem-link:hover .ui-menuitem-text,
	.ui-menubar-custom:visited,
	.ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-text,
	.ui-menubar-custom,
	a {
		color: $menuBgColor;
		display: inline-block;
	}
	.ui-menubar-custom {
		float: right;
		margin-top: 1em;
		margin-right: 1em;
		margin-bottom: 1em;
	}
}

body .ui-steps {
	.ui-steps-item .ui-menuitem-link {
		.ui-steps-title {
			white-space: unset;
			margin-top: 0;
		}
		.ui-steps-number {
			top: 8px;
		}
	}
	.ui-steps-item.ui-state-disabled {
		opacity: 1;
		.ui-steps-number {
			color: $stepsItemTextColor;
		}
	}
}

body .ui-steps:before {
	position: relative;
	top: 34px;
}

@media (max-width: 640px) {
	body .ui-steps:before {
		position: relative;
		top: 29px;
	}
}

@media (max-width: 767px) {
	body .ui-steps .ui-steps-item .ui-menuitem-link .ui-steps-title {
		display: none;
	}
}


