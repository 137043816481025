@import 'variables';

/*Layout*/

//sidebar margin
.ui-menubar .ui-menubar-root-list{
	margin: 0;
}
// sidebar padding
.ui-menubar .ui-menuitem .ui-menuitem-link { /* First Level items */
	text-decoration: none;
	padding: 1em !important;
}
// sidebar properties
.ui-sidebar{
	padding: 0;
	overflow-y: auto;
}
// sidebar properties
.ui-sidebar .ui-menubar .ui-menubar-root-list, .ui-sidebar .ui-menubar .ui-menubar-root-list>.ui-menuitem{
	display: block;
	width: 100%;
}

// side bar close icon properties
.ui-sidebar-close{
	z-index: 10000;
	position: absolute;
	right: 0;
	padding: .125em .25em;
}
// hamburger menu button padding
.btn-sidebar button{
	padding: .25em .5em;
}
// sidebar properties
.ui-menubar .ui-menubar-custom {
	float: none;
	margin: 0;
	padding: 0;
}
// sidebar properties
.ui-menubar .ui-menubar-custom .sidebar-app-login a{
	display: block;
	width: 100%;
	padding: .75em;
}

/*Theme*/

// Menu properties
.ui-menubar .ui-menuitem .ui-menuitem-link { /* First Level items */
	color: $primaryTextColor;
	background: none !important;
	border-radius: 0;
}
// Menu background color
.ui-menubar .ui-menuitem .ui-menuitem-link:hover, .ui-menubar .ui-menubar-custom .sidebar-app-login a:hover{
	background-color: $sidebar-app-login-bg !important;
}
// Menu background color
.ui-menubar .ui-menuitem .ui-menuitem-link:active, .ui-menubar .ui-menubar-custom .sidebar-app-login a:active{
	background-color: $sidebar-app-login-bg !important;
	box-shadow: inset 0 0 7px $sidebar-app-login-color;
}
 //sidebar bg
/*.ui-menubar .ui-menuitem .ui-menuitem-link.ui-state-active{
	background-color: $btn-sidebar-bg !important; //required due to PrimeNG scripted style
	box-shadow: inset 0 0 7px $ui-menubar-box-shadow-color;
	transition: background-color .5s ease-in;
}*/

// sidebar border
.ui-sidebar .ui-menubar .ui-menuitem .ui-menuitem-link{
	border-bottom: 1px solid $ui-menubar-border;
}

// sidebar close properties
.ui-sidebar-close{
	color: $ford-blue;
	background: $ui-sidebar-bg;
}
// hamburger menu properties
.btn-sidebar button{
	background: $ui-sidebar-bg;
	border: 0;
	outline: none;
	font-size: 1.5em;
	color: $primaryTextColor;
	border-radius: 20%;
}

// sidebar properties
.ui-menubar .ui-menubar-custom .sidebar-app-login a{
	color: $white !important; //required due to PrimeNG scripted style
	background: none;
	border-radius: 0;
	border-bottom: 1px solid $ui-menubar-border;
}
// check with team
.ui-menubar .ui-menubar-custom .sidebar-app-login .fa{
	color: $menuBgColor;
}