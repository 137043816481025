@import 'variables';
/*Layout*/
// following two classes belongs to export buttons
.export-btn-group {
	float: right;
}
body .ui-button {
	margin: .125em;
	border: none;
}




/*Theme*/
// select-all/select-none
.ui-button-link {
	background:none!important;
	border:none;
	outline: none;
	cursor:pointer;
	color: $ui-menuitem-link-color;
	font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
	font-size: 16px;
}
.ui-button-link:hover {
	color: $btn-sidebar-bg;
}